<template>
  <div class="export-excel">
    <el-form inline>
      <el-form-item>
        <el-select v-model="year">
          <el-option v-for="item of years" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="startMonth">
          <el-option v-for="item of months" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="endMonth">
          <el-option v-for="item of months" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleExport" type="primary">导出</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      year: 2021,
      years: [
        2020,
        2021,
        2022
      ],
      startMonth: 1,
      endMonth: moment().month() + 1,
      months: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
      ]
    }
  },
  methods: {
    handleExport () {
      if (this.endMonth < this.startMonth) {
        this.$message.error('月份选择有误')
        return
      }

      window.open(`http://vpn.ljkjkf.com:8098?year=${this.year}&startMonth=${this.startMonth}&endMonth=${this.endMonth}`)
    }
  }
}
</script>
